export const ActionTitles = {
  //Common
  CREATE: "Create",
  UPDATE: "Update",
  DELETE: "Delete",
  VIEW: "View",
  SAVE: "Save",
  CANCEL: "Cancel",
  CLOSE: "Close",
  DOWNLOAD: "Download",
  FILTER: "Filter",
  BACK: "Back",
  INSERT: "Insert",
  ABOVE: "Above",
  BELOW: "Below",
  ERROR: "Error",
  ERRORS: "Errors",
  FIND: "Find",
  TRANSACTION: "Transaction",

  //partner related constants
  CORPORATE_PARTNERS: "Corporate Partners",
  CORPORATE_PARTNER: "Corporate Partner",
  CORPORATE_PARTNER_DETAILS: "Corporate Partner Details",
  CORPORATE_PARTNER_UPDATE: "Corporate Partner Update",
  CREATE_CORPORATE_PARTNER: "Create Corporate Partner",
  CREATE_AND_ADD_ANOTHER: "Create & Add Another",
  CREATE_AND_SEND_EMAIL: "Create & Send Email",
  UPDATE_AND_CONTINUE: "Update & Keep Editing",

  // Default Content related constants
  DEFAULT_CONTENT: "Default Content",
  CREATE_DEFAULT_CONTENT: "Create Default Content",
  DEFAULT_CONTENT_DETAILS: "Default Content Details",
  DEFAULT_CONTENT_UPDATE: "Default Content Update",
  DEFAULT_CONTENT_DELETE: "Delete",

  // Matter related constants
  MATTERS: "Matters",
  MATTER: "Matter",
  CREATE_MATTER: "Create Matter",
  MATTER_DETAILS: "Matter Details",
  MATTER_UPDATE: "Matter Update",
  MATTER_ACCOUNTS: "Accounts",
  MATTER_ACCOUNT_DETAILS: "Account Details",
  MATTER_TRANSACTIONS: "Transactions",
  MATTER_BANK_STATEMENT_TITLE: "Convert Bank Statement",
  MATTER_BANK_STATEMENT_IMPORT: "Import",
  MATTER_BANK_STATEMENT_CONVERT: "Convert",
  MATTER_UPLOAD_STATEMENTS_AND_FILES: "Upload Statements / Files",
  MATTER_ADD_TRANSACTIONS_FROM_FILE: "Add Transaction From File",

  // reports related constants
  REPORTS: "Reports",
  REPORT: "Report",
  CREATE_REPORT: "Create Report",
  REPORT_DETAILS: "Report Details",
  GENERATE_REPORT: "Generate Report",
  VIEW_REPORTS: "View Reports",

  // Schedules
  SCHEDULES: "Schedules",
  SCHEDULE_DETAILS : "Schedule Details",

  //Settings
  SETTINGS: "Settings",

  //Users
  USERS: "Users",
  USER: "User",
  CREATE_USER: "Create User",
  USER_DETAILS: "User Details",
  USER_UPDATE: "User Update",
  
  //Risk Insights
  CLIENT_RISK_INSIGHTS: "Client Risk Insights",
  CREATE_CLIENT_RISK_INSIGHTS: "Create Client Risk Insights",
  CLIENT_RISK_INSIGHTS_UPDATE: "Client Risk Insights Update",
  
  //Client Defaults
  CLIENT_DEFAULTS: "Client Defaults",
  CREATE_CLIENT_DEFAULTS: "Create Client Defaults",
  CLIENT_DEFAULTS_UPDATE: "Client Defaults Update",

  //Status Messages
  STATUS_MESSAGE_DETAILS: "Status Message Details",
  STATUS_MESSAGES: "Status Messages",
  STATUS_MESSAGE_SHOW: "Show Status Messages",
  STATUS_MESSAGE_CREATE: "Create Status Messages",
  STATUS_MESSAGE_UPDATE: "Update Status Messages",
  STATUS_MESSAGE_DELETE: "Delete Status Messages",

};
Object.freeze(ActionTitles);

export const UserRoles = {
  //Roles
  USER_ROLE_AFT_ADMIN: "ROLE_AFT_ADMIN",
  USER_ROLE_AFT_SYSADMIN: "ROLE_AFT_SYSADMIN",
  USER_ROLE_CLIENT_ADMIN: "ROLE_CLIENT_ADMIN",
  USER_ROLE_CLIENT: "ROLE_CLIENT",
  USER_ROLE_INDIVIDUAL: "ROLE_INDIVIDUAL",
  //added for the case when jwt token comes from keycloak (above once to be removed later)
  USER_AFT_ADMIN: "AFT_ADMIN",
  USER_AFT_SYSADMIN: "AFT_SYSADMIN",
  USER_CLIENT_ADMIN: "CLIENT_ADMIN",
  USER_CLIENT: "CLIENT",
  USER_INDIVIDUAL: "INDIVIDUAL",
  USER_TXN_EDITOR: "TXN_EDITOR",
};
Object.freeze(UserRoles);

export const AdminRolesArray = [
  UserRoles.USER_ROLE_AFT_ADMIN,
  UserRoles.USER_ROLE_AFT_SYSADMIN,
  UserRoles.USER_AFT_ADMIN,
  UserRoles.USER_AFT_SYSADMIN,
];

export const ROLES = {
  ROLE_CLIENT: "ROLE_CLIENT",
  ROLE_CLIENT_ADMIN: "ROLE_CLIENT_ADMIN",
  ROLE_TXN_EDITOR: "ROLE_TXN_EDITOR",
}

export const ROLE_NAMES = {
  ROLE_CLIENT: "CLIENT",
  ROLE_CLIENT_ADMIN: "CLIENT ADMIN",
  ROLE_TXN_EDITOR: "TXN EDITOR"
}

export const ROLE_OPTIONS = [
  { id: ROLES.ROLE_CLIENT, name: ROLE_NAMES.ROLE_CLIENT },
  { id: ROLES.ROLE_CLIENT_ADMIN, name: ROLE_NAMES.ROLE_CLIENT_ADMIN },
  { id: ROLES.ROLE_TXN_EDITOR, name: ROLE_NAMES.ROLE_TXN_EDITOR }
];

export const ROLE_OPTIONS_FOR_CLIENT_ADMIN = [
  { id: ROLES.ROLE_CLIENT_ADMIN, name: ROLE_NAMES.ROLE_CLIENT_ADMIN },
  { id: ROLES.ROLE_TXN_EDITOR, name: ROLE_NAMES.ROLE_TXN_EDITOR }
];
export const VALID_ROLES_TO_ACCESS_PORTAL = [
  ...AdminRolesArray,
  UserRoles.USER_ROLE_CLIENT_ADMIN,
  UserRoles.USER_ROLE_CLIENT,
  UserRoles.USER_CLIENT_ADMIN,
  UserRoles.USER_CLIENT,
]

export const RESOURCES = {
  CORPORATE_PARTNERS: "corporate-partners",
  DEFAULT_CONTENT: "default-content",
  REPORTS: "reports",
  MATTERS: "matters",
  SCHEDULES : "Schedules",
  SETTINGS: "settings",
  USERS: "users",
  SURVEYS: "survey",
  RISK_INSIGHTS: "risk-insights",
  CLIENT_DEFAULTS: "client-defaults",
  STATUS_MESSAGES: "status-messages",
  ACCOUNTS: "accounts"
};

export const SUB_RESOURCES = {
  ACCOUNTS: "accounts",
  TRANSACTIONS: "transactions",
}

export const RESOURCES_BASEPATH = {
  CORPORATE_PARTNERS: "/corporate-partners",
  DEFAULT_CONTENT: "/default-content",
  REPORTS: "/reports",
  MATTERS: "/matters",
  SCHEDULES : "/Schedules",
  SETTINGS: "/settings",
  USERS: "/users",
  SURVEYS: "/survey",
  RISK_INSIGHTS: "/risk-insights",
  CLIENT_DEFAULTS: "/client-defaults",
  STATUS_MESSAGES: "/status-messages",
  ACCOUNTS: "/accounts",
  TRANSACTIONS: "/transactions"
};

export const RESOURCES_META_DATA = {
  [RESOURCES.CORPORATE_PARTNERS]: {
    displayName: "Corporate Partners",
    apiEndpoint: "admin/client",
  },
  [RESOURCES.DEFAULT_CONTENT]: {
    displayName: "Default Content",
    apiEndpoint: "admin/content",
  },
  [RESOURCES.REPORTS]: {
    displayName: "Reports",
    apiEndpoint: "api/report-request", // By Default apiEndpoint will be used for all CRUD calls if specific api endpoint is not provided
    getAllEndpoint: "api/client/report-request", // Like if getAllEndpoint is provided here so that will be used
    getOneEndpoint: "api/client/report-request",
  },
  [RESOURCES.MATTERS]: {
    displayName: "Matters",
    apiEndpoint: "matter",
    getAllEndpoint: "matters"
  },
  [RESOURCES.SCHEDULES]: {
    displayName: "Schedules",
    apiEndpoint: "api/report-request-schedule",
  },
  [RESOURCES.SETTINGS]: {
    displayName: "Settings",
    apiEndpoint: "user",
  },
  [RESOURCES.USERS]: {
    displayName: "Users",
    apiEndpoint: "admin/user",
    clientAdminApiEndpoint: "client-admin/client/user"
  },
  [RESOURCES.SURVEYS]: {
    displayName: "Questionnaires",
    apiEndpoint: "client-survey",
  },
  [RESOURCES.RISK_INSIGHTS]: {
    displayName: "Risk Insights",
    apiEndpoint: "risk-insights",
  },
  [RESOURCES.CLIENT_DEFAULTS]: {
    displayName: "Client Defaults",
    apiEndpoint: "admin/client-default/client",
  },
  [RESOURCES.STATUS_MESSAGES]: {
    displayName: "Status Messages",
    apiEndpoint: "admin/status-message",
  },
  [SUB_RESOURCES.ACCOUNTS]: {
    displayName: "Accounts",
    apiEndpoint: "account",
    getAllEndpoint: "accounts"
  },
  [SUB_RESOURCES.TRANSACTIONS]: {
    displayName: "Transactions",
    apiEndpoint: "transactions",
  },
};

export const REPORT_TYPES = {
  ACCOUNT_VERIFICATION: "ACCOUNT_VERIFICATION",
  ACCOUNT_VERIFICATION_PLUS: "ACCOUNT_VERIFICATION_PLUS",
  PROOF_OF_FUNDS: "PROOF_OF_FUNDS",
  SOURCE_OF_FUNDS_DATA: "SOURCE_OF_FUNDS",
  SOURCE_OF_FUNDS_ANALYSIS: "SOURCE_OF_FUNDS_ANALYSIS",
  TRANSACTION_ANALYSIS: "TRANSACTION_ANALYSIS",
  CLAIMS_REVIEW: "CLAIMS_REVIEW",
  TRANSACTION_DOWNLOAD: "TRANSACTION_DOWNLOAD",
  SURVEY_ONLY: "SURVEY_ONLY",
  INCOME: "INCOME",
  AFFORDABILITY: "AFFORDABILITY",
  DETAILED_AFFORDABILITY: "DETAILED_AFFORDABILITY"
};

export const REPORT_TYPES_DISPLAY_NAMES = {
  [REPORT_TYPES.ACCOUNT_VERIFICATION]: 'Account Verification',
  [REPORT_TYPES.ACCOUNT_VERIFICATION_PLUS]: 'Account Verification Plus',
  [REPORT_TYPES.PROOF_OF_FUNDS]: 'Proof of Funds',
  [REPORT_TYPES.SOURCE_OF_FUNDS_DATA]: 'Source of Funds Data',
  [REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS]: 'Source of Funds Analysis',
  [REPORT_TYPES.TRANSACTION_ANALYSIS]: 'Transaction Analysis',
  [REPORT_TYPES.CLAIMS_REVIEW]: 'Claims Review',
  [REPORT_TYPES.TRANSACTION_DOWNLOAD]: 'Transaction Download',
  [REPORT_TYPES.SURVEY_ONLY]: 'Questionnaire Only',
  [REPORT_TYPES.INCOME]: 'Income',
  [REPORT_TYPES.AFFORDABILITY]: 'Affordability',
  [REPORT_TYPES.DETAILED_AFFORDABILITY]: 'Detailed Affordability',
};

export const SUPPORTED_CURRENCIES = {
  POUND: 'GBP',
  EURO: 'EUR',
  DOLLAR: 'USD'
}

export const CURRENCY_SYMBOLS = {
  GBP: "\u00a3",
  EUR: "\u20ac",
  USD: "\u0024",
};

export const TIME_RANGE_MONTHS = {
  1: "1_MONTH",
  3: "3_MONTHS",
  6: "6_MONTHS",
  12: "1_YEAR",
  24: "2_YEARS",
  120: "MAX"
};

export const TIME_RANGES = {
  MONTH_1: '1',
  MONTHS_3: '3',
  MONTHS_6: '6',
  YEAR_1: '12',
  YEARS_2: '24',
  MAX: '120',
  CUSTOM: 'CUSTOM'
};

export const TIME_RANGES_STRING = {
  'MONTH_1' : '1 Month',
  'MONTHS_3' : '3 Months',
  'MONTHS_6' : '6 Months',
  'YEAR_1' : '1 Year',
  'YEARS_2' : '2 Years',
  'MAX' : 'Max',
  'CUSTOM' : 'Custom'
};

export const TIME_RANGES_STRING_VALUES = {
  'MONTH_1' : '1',
  'MONTHS_3' : '3',
  'MONTHS_6' : '6',
  'YEAR_1' : '12',
  'YEARS_2' : '24',
  'MAX' : '120',
  'CUSTOM' : 'CUSTOM'
};

export const DEFAULT_TIME_RANGES_FOR_REPORT_TYPE = {  //When adding a new report type that needs a time range input, make sure to add a default value.
  [REPORT_TYPES.SOURCE_OF_FUNDS_DATA]: TIME_RANGES.MONTHS_3,
  [REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS]: TIME_RANGES.YEAR_1,
  [REPORT_TYPES.TRANSACTION_ANALYSIS]: TIME_RANGES.YEAR_1,
  [REPORT_TYPES.CLAIMS_REVIEW]: TIME_RANGES.MAX,
  [REPORT_TYPES.TRANSACTION_DOWNLOAD]: TIME_RANGES.MONTHS_3,
  [REPORT_TYPES.INCOME]: TIME_RANGES.MONTHS_3,
  [REPORT_TYPES.AFFORDABILITY]: TIME_RANGES.MONTHS_3,
  [REPORT_TYPES.DETAILED_AFFORDABILITY]: TIME_RANGES.MONTHS_3,
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_LOCALE = "Europe/London";

export const REPORT_FORMATS = {
  JSON: 'JSON',
  PDF: 'PDF',
  CSV: 'CSV',
  EXCEL: 'EXCEL'
};
export const REPORT_FORMATS_CSV = {
  CSV: 'CSV',
  QUICKBOOKS: 'QUICKBOOKS',
  XERO: 'XERO',
};
export const REPORT_FORMATS_CSV_ZIP = {
  QUICKBOOKS: 'QUICKBOOKS',
  XERO: 'XERO',
};
export const REPORT_FORMATS_CSV_FILTER = [
  { id: "CSV", name: "CSV" },
  { id: "QUICKBOOKS", name: "QUICKBOOKS" },
  { id: "XERO", name: "XERO" },
];

export const DefaultContentTypes = [
  {name: "Terms and Conditions", id: 'TERMS'},
  {name: "Privacy Policy", id: 'PRIVACY_POLICY'},
  {name: "Splash Screen", id: 'SPLASH_SCREEN'},
  {name: "More Information", id: 'MORE_INFORMATION'},
];
Object.freeze(DefaultContentTypes);

export const RichTextEditorToolbarOptions = [
  ['link', 'image', 'code-block'],
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'align': [] }],
  [{ 'link' : []}]

  ['clean']                                         // remove formatting button
];
Object.freeze(RichTextEditorToolbarOptions);


export const REPORT_STATUSES = {
  NEW: 'NEW',
  ACCEPTED: 'ACCEPTED',
  PROCESSING: 'PROCESSING',
  DRAFT: 'DRAFT',
  AVAILABLE: 'AVAILABLE',
  REJECTED: 'REJECTED',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  DECLINED: 'DECLINED',
  VIEWED: 'VIEWED',
};
export const ACTIVE_REPORT_STATUSES = ['NEW', 'ACCEPTED', 'PROCESSING', 'DRAFT', 'VIEWED'];

export const REPORT_STATUS_FILTER = [
  { id: "NEW", name: "NEW" },
  { id: "ACCEPTED", name: "ACCEPTED" },
  { id: "PROCESSING", name: "PROCESSING" },
  { id: "DRAFT", name: "DRAFT" },
  { id: "AVAILABLE", name: "AVAILABLE" },
  { id: "EXPIRED", name: "EXPIRED" },
  { id: "REJECTED", name: "REJECTED" },
  { id: "FAILED", name: "FAILED" },
  { id: "REVOKED", name: "REVOKED" },
  { id: "CANCELLED", name: "CANCELLED" },
  { id: "DECLINED", name: "DECLINED" },
  { id: "VIEWED", name: "VIEWED" },
];

export const SCHEDULE_STATUS_FILTER = [
  { id: "ACTIVE", name: "ACTIVE" },
  { id: "INACTIVE", name: "INACTIVE" },
];

export const SCHEDULE_FREQUENCY_FILTER = [
  { id: "MONTHLY", name: "MONTHLY" },
  { id: "QUARTERLY", name: "QUARTERLY" },
  { id: "ANNUALLY", name: "YEARLY" }
];

export const SEARCH_BY_FIELDS = [
  { id: "requestId", name: "REQUEST ID" },
  { id: "clientRequestId", name: "CLIENT REQUEST ID" },
  { id: "emailAddress", name: "EMAIL" },
  { id: "accountName", name: "CONTACT NAME" },
  { id: "businessName", name: "BUSINESS NAME" },
  { id: "clientReference", name: "CLIENT REFERENCE" },
];

export const SEARCH_BY_FIELDS_CLIENTS = [
  { id: "email", name: "EMAIL" },
  { id: "clientName", name: "CLIENT NAME" },
];

export const SEARCH_BY_FIELDS_USERS = [
  { id: "email", name: "EMAIL" },
  { id: "fullName", name: "USER NAME" },
];

export const DEFAULT_COLOURS = {
  PRIMARY_COLOUR: "#93002c",
  SECONDARY_COLOUR: "#383848",
  TERTIARY_COLOUR: "#000000",
};

export const DISPLAY_NAMES = {
  [RESOURCES.CORPORATE_PARTNERS] : "Corporate Partner",
  [RESOURCES.USERS] : "User",
  [RESOURCES.DEFAULT_CONTENT] : "Content",
  [RESOURCES.MATTERS] : "Matter",
  [RESOURCES.REPORTS] : "Report",
  [RESOURCES.RISK_INSIGHTS] : "Client Risk Insights",
  [RESOURCES.SETTINGS] : "Settings",
  [RESOURCES.CLIENT_DEFAULTS] : "Client Defaults",
  [RESOURCES.STATUS_MESSAGES] : "Status Messages",
};

export const REPORT_BRANDING_TYPES = {
  NONE : 'NONE',
  DEFAULT : 'DEFAULT' ,
  CLIENT : 'CLIENT' ,
  BOTH : 'BOTH',
};

export const REPORT_BRANDING_TYPES_DISPLAY_NAMES ={ 
  [REPORT_BRANDING_TYPES.NONE] : 'None',
  [REPORT_BRANDING_TYPES.DEFAULT] : 'Default' ,
  [REPORT_BRANDING_TYPES.CLIENT] : 'Client' ,
  [REPORT_BRANDING_TYPES.BOTH] : 'Both',
};

export const REPORT_BRANDING_TYPES_OPTIONS =[
  { id: REPORT_BRANDING_TYPES.NONE, name: 'None' },
  { id: REPORT_BRANDING_TYPES.DEFAULT, name: 'Default' },
  { id: REPORT_BRANDING_TYPES.CLIENT, name: 'Client' },
  { id: REPORT_BRANDING_TYPES.BOTH, name: 'Both' },
];

export const FREQUENCY_OPTIONS = [
  {id: "ONCE", name: "Once"},
  {id: "MONTHLY", name: "Monthly"},
  {id: "QUARTERLY", name: "Quarterly"},
  {id: "ANNUALLY", name: "Annual"}
];

export const FREQUENCY = {
  ONCE: 'ONCE',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  ANNUALLY: 'ANNUALLY'
};

export const FREQUENCY_TYPES_DISPLAY_NAMES = {
  [FREQUENCY.ONCE]: 'Once',
  [FREQUENCY.MONTHLY]: 'Monthly',
  [FREQUENCY.QUARTERLY]: 'Quarterly',
  [FREQUENCY.ANNUALLY]: 'Annual',
};

export const TIME_RANGE_INPUT_OPTIONS = [
  {id: "DEFAULT", name: "Default"},
  {id: "DATE_RANGE", name: "Date Range"},
  {id: "MID_DATE_RANGE", name: "Mid Date Range"}
];

export const TIME_RANGE_INPUT = {
  REPORT_LENGTH: 'REPORT_LENGTH',
  DATE_RANGE: 'DATE_RANGE',
  MID_DATE_RANGE: 'MID_DATE_RANGE'
};

export const TIME_RANGE_INPUT_TYPES_DISPLAY_NAMES = {
  [TIME_RANGE_INPUT.REPORT_LENGTH]: 'Default',
  [TIME_RANGE_INPUT.DATE_RANGE]: 'Date Range',
  [TIME_RANGE_INPUT.MID_DATE_RANGE]: 'Mid Date Range'
};

export const SCHEDULE_STATUS = {
  ACTIVE : "ACTIVE",
  INACTIVE : "INACTIVE",
};

export const SCHEDULE_ACTIONS = {
  STOP_SCHEDULE : "STOP SCHEDULE",
  TOGGLE_REMINDERS : "TOGGLE REMINDERS",
  VIEW_REPORTS : "VIEW REPORTS",
  EXIT : "EXIT"
};

export const SCHEDULE_ACTIONS_DISPLAY = {
  [SCHEDULE_ACTIONS.STOP_SCHEDULE] : "Stop Schedule",
  [SCHEDULE_ACTIONS.TOGGLE_REMINDERS] : "Toggle Reminders",
  [SCHEDULE_ACTIONS.VIEW_REPORTS] : "View Reports",
  [SCHEDULE_ACTIONS.EXIT] : "Exit"
}

export const SURVEY_TYPES = {
  SOURCE_OF_FUNDS: 'SOURCE_OF_FUNDS',
  FACT_FIND: 'FACT_FIND'
}

export const SURVEY_SUPPORT_TYPES ={
  DEFAULTING : 'DEFAULTING',
  SIMPLE : 'SIMPLE' ,
  FULL : 'FULL' ,
  NONE : 'NONE',
};

export const SURVEY_SUPPORT_TYPES_OPTIONS =[
  { id: SURVEY_SUPPORT_TYPES.DEFAULTING, name: 'Defaulting' },
  { id: SURVEY_SUPPORT_TYPES.SIMPLE, name: 'Simple' },
  { id: SURVEY_SUPPORT_TYPES.FULL, name: 'Full' },
  { id: SURVEY_SUPPORT_TYPES.NONE, name: 'None' },
];

export const SURVEY_SUPPORT_TYPES_DISPLAY_NAMES ={ 
  [SURVEY_SUPPORT_TYPES.DEFAULTING] : 'Defaulting',
  [SURVEY_SUPPORT_TYPES.SIMPLE] : 'Simple' ,
  [SURVEY_SUPPORT_TYPES.FULL] : 'Full' ,
  [SURVEY_SUPPORT_TYPES.NONE] : 'None',
};

export const ReportTypeChoices = [
  {
    id: REPORT_TYPES.ACCOUNT_VERIFICATION,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.ACCOUNT_VERIFICATION],
  },
  {
    id: REPORT_TYPES.ACCOUNT_VERIFICATION_PLUS,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.ACCOUNT_VERIFICATION_PLUS],
  },
  {
    id: REPORT_TYPES.PROOF_OF_FUNDS,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.PROOF_OF_FUNDS],
  },
  {
    id: REPORT_TYPES.SOURCE_OF_FUNDS_DATA,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.SOURCE_OF_FUNDS_DATA],
  },
  {
    id: REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.SOURCE_OF_FUNDS_ANALYSIS],
  },
  {
    id: REPORT_TYPES.TRANSACTION_ANALYSIS,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.TRANSACTION_ANALYSIS],
  },
  {
    id: REPORT_TYPES.CLAIMS_REVIEW,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.CLAIMS_REVIEW],
  },
  {
    id: REPORT_TYPES.TRANSACTION_DOWNLOAD,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.TRANSACTION_DOWNLOAD],
  },
  {
    id: REPORT_TYPES.SURVEY_ONLY,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.SURVEY_ONLY],
  },
  {
    id: REPORT_TYPES.INCOME,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.INCOME],
  },
  {
    id: REPORT_TYPES.AFFORDABILITY,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.AFFORDABILITY],
  },
  {
    id: REPORT_TYPES.DETAILED_AFFORDABILITY,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.DETAILED_AFFORDABILITY],
  },
];

export const INCOME_REPORT_TYPE_CHOICES = [
  {
    id: REPORT_TYPES.INCOME,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.INCOME],
  },
  {
    id: REPORT_TYPES.AFFORDABILITY,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.AFFORDABILITY],
  },
  {
    id: REPORT_TYPES.DETAILED_AFFORDABILITY,
    name: REPORT_TYPES_DISPLAY_NAMES[REPORT_TYPES.DETAILED_AFFORDABILITY],
  },
];

export const CurrencyChoices = [
  { id: SUPPORTED_CURRENCIES.POUND, name: '\u00a3' },
  { id: SUPPORTED_CURRENCIES.EURO, name: '\u20ac' },
  { id: SUPPORTED_CURRENCIES.DOLLAR, name: '\u0024' },
];

export const TEMPLATES_ASSIGNMENT = {
  MAP : 'templatesToMap' ,
  UNMAP : 'templatesToUnmap' ,
};

export const TemplateType = {
  CREATE_REPORT_TEMPLATE: 'CREATE_REPORT_TEMPLATE',
  EXPAND_REPORT_TEMPLATE: 'EXPAND_REPORT_TEMPLATE'
};

export const ROLES_ASSIGNMENT = {
  MAP : 'rolesToMap' ,
  UNMAP : 'rolesToUnmap' ,
};

export const SURVEYS_ASSIGNMENT = {
  REPORT_TYPE: 'reportType',
  MAP : 'surveysToMap' ,
  UNMAP : 'surveysToUnmap' ,
};

export const DELETE_USER = {  
  PENDING_SCHEDULE_ERROR_CODE : 6009,
}

export const CREATE_USER = {
  SAME_NAME_OR_EMAIL_ERROR_CODE : 6002,
  SAME_NAME_OR_EMAIL_ERROR_MESSAGE : "Unfortunately we are unable to create this user. If this issue persists please contact the Armalytix Support Team",
}

export const INSIGHTS_TYPE = {
  INCOME : "INCOME",
}

export const IMAGE_COMPRESSION = {
  QUALITY : 0.6,
  CONVERT_SIZE : 70000, //70KB, To compress PNG images, a smaller size limit has been mentioned.
  LOWER_COMPRESSION_LIMIT : 70000,  //Compression won't be applied to images smaller than given value.
  UPPER_COMPRESSION_LIMIT : 100000, //It is recommended that the compressed image should be smaller than this.
  COMPRESSION_ERROR_MESSAGE : "File size exceeds the allowed limit, please upload a smaller one.",
}

export const REPORT_STATUS_INFO = {
  NEW: "The report has not been actioned by the client.",
  VIEWED: "The report has been viewed by the client.",
  ACCEPTED: "The client has picked up the request and logged in, and is at the point of connecting bank accounts.",
  PROCESSING: "The client has started generating a report. This is a temporary state that will change to DRAFT when the report is ready for sharing.",
  DRAFT: "The client has generated a draft report but has not hit the `Share my report` button. If they log back in and hit `Share` then the report will move to AVAILABLE.",
  AVAILABLE: "The report is available for you to download.",
  REJECTED: "The client decided not to share the report with you and instead pressed `Do not share`.",
  FAILED : "The email could not be delivered to the client.",
  DECLINED: "The client's bank was not available for connection via Open Banking or did not cover the timeframe required, so the request ended without being completed"
};


export const RISK_INSIGHTS_COMPARISON_OPTIONS = [
  { id: 'equals', name: 'Equals' },
  { id: 'notEqual', name: 'Not Equal' },
  { id: 'greaterThan', name: 'Greater Than' },
  { id: 'lessThan', name: 'Less Than' },
  { id: 'greaterThanOrEqual', name: 'Greater Than Or Equal' },
  { id: 'lessThanOrEqual', name: 'Less Than Or Equal' },
  { id: 'absGreaterThan', name: 'Abs Greater Than' },
  { id: 'absLessThan', name: 'Abs Less Than' },
  { id: 'absGreaterThanOrEqual', name: 'Abs Greater Than Or Equal' }, 
  { id: 'absLessThanOrEqual', name: 'Abs Less Than Or Equal' },
];

export const INSIGHTS_REPORT_TYPE = {
  RISK_INSIGHTS : "RISK_INSIGHTS",
}
 
 export const INSIGHTS_PERMISSION_TYPE = {
  RISK_INSIGHTS : "RISK_INSIGHTS",
  INCOME_INSIGHTS : "INCOME_INSIGHTS"
}

export const DISPLAY_OPEN_EMAIL_CLIENT_BUTTON = ['s','h','o','w','O','E','C'];

export const ENABLED_FEATURES_ENUM = {
  EXTENDED_DATA_RANGE : "extendedDataRange",
  INTERCOM_MUST_SEARCH : "intercomMustSearch",
  INTERCOM_CHAT_ENABLED : "intercomChatEnabled",
  STATEMENT_OCR_ANALYSIS : "statementOcrAnalysis",
  GROUPING_LARGE_ITEM : "groupingLargeItem",
  EMAIL_MASKING: "emailMasking",
  SOFA_AFFORDABILITY: "sofaAffordability",
  EXPAND_REPORT: "expandReport",
  MATTER_ENABLED: "matterEnabled",
  IMPORT_CSV: "importCsv",
  UPLOAD_BANK_STATEMENT : "uploadBankStatement"
}

export const ENABLED_FEATURES = [
  {
    name: ENABLED_FEATURES_ENUM.STATEMENT_OCR_ANALYSIS,
    title: "Statement OCR Analysis",
  },
  {
    name: ENABLED_FEATURES_ENUM.EXTENDED_DATA_RANGE,
    title: "Extended Date Range",
  },
  {
    name: ENABLED_FEATURES_ENUM.INTERCOM_MUST_SEARCH,
    title: "Intercom Must Search",
  },
  {
    name: ENABLED_FEATURES_ENUM.INTERCOM_CHAT_ENABLED,
    title: "Intercom Chat Enabled",
  },
  {
    name: ENABLED_FEATURES_ENUM.EMAIL_MASKING,
    title: "Email Masking",
  }, 
  {
    name: ENABLED_FEATURES_ENUM.GROUPING_LARGE_ITEM,
    title: "Grouping Large Item",
  },
  {
    name: ENABLED_FEATURES_ENUM.SOFA_AFFORDABILITY,
    title: "Source of Funds Analysis Affordability",
  },
  {
    name: ENABLED_FEATURES_ENUM.EXPAND_REPORT,
    title: "Expand Report",
  },
  {
    name: ENABLED_FEATURES_ENUM.MATTER_ENABLED,
    title: "Enable Matters",
  },
  {
    name: ENABLED_FEATURES_ENUM.IMPORT_CSV,
    title: "Import CSVs",
  },
  {
    name: ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT,
    title: "Upload Bank Statements",
  },
];

export const EXCLUDE_ENABLED_FEATURES_FOR_FORM = [
  ENABLED_FEATURES_ENUM.MATTER_ENABLED,
  ENABLED_FEATURES_ENUM.IMPORT_CSV,
  ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT,
];

export const CLIENT_DEFAULT_PARAMS = {
    LARGE_ITEM_GROUP_EXPENSE : "LARGE_ITEM_GROUP_EXPENSE",
    LARGE_ITEM_GROUP_INCOME : "LARGE_ITEM_GROUP_INCOME",
}

export const FOLLOW_UP_DATE_RANGE = {
  MONTH_1 : "MONTH_1",
  MONTHS_3 : "MONTHS_3",
  MONTHS_6 : "MONTHS_6",
  YEAR_1 : "YEAR_1",
  YEARS_2 : "YEARS_2",
}

export const FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES = {
  MONTH_1 : "1 Month",
  MONTHS_3 : "3 Months",
  MONTHS_6 : "6 Months",
  YEAR_1 : "1 Year",
  YEARS_2 : "2 Years",
}

export const FOLLOW_UP_DATE_RANGE_MONTHS = {
  MONTH_1 : 1,
  MONTHS_3 : 3,
  MONTHS_6 : 6,
  YEAR_1 : 12,
  YEARS_2 : 24,  
}

export const FOLLOW_UP_DATE_RANGE_CHOICES = [
  {
    id : FOLLOW_UP_DATE_RANGE.MONTH_1,
    name : FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES.MONTH_1
  },
  {
    id : FOLLOW_UP_DATE_RANGE.MONTHS_3,
    name : FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES.MONTHS_3
  },
  {
    id : FOLLOW_UP_DATE_RANGE.MONTHS_6,
    name : FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES.MONTHS_6
  },
  {
    id : FOLLOW_UP_DATE_RANGE.YEAR_1,
    name : FOLLOW_UP_DATE_RANGE_DISPLAY_NAMES.YEAR_1
  }
];

export const armalytixFont = [//The Styles are not overriding hence applied directly to cell.
  '"Gilroy"',
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Roboto"',
  '"Oxygen"',
  '"Ubuntu"',
  '"Cantarell"',
  '"Fira Sans"',
  '"Droid Sans"',
  '"Helvetica Neue"',
  "sans-serif",
].join(",");

export const FILE_NAME_ALLOWED_LENGTH_FOR_DOWNLOAD = 100;

export const EVENT_REASON = {
  BACKDROP_CLICK : "backdropClick",
}

export const CLIENT_SUSPENDED = {
  ERROR_CODE : 403,
  ERROR_MESSAGE : "Access denied: Your account has been suspended. Please contact your Armalytix representative for assistance.",
}

export const LOCAL_STORAGE_KEYS = {
  CLIENT_SUSPENDED : "clientSuspended",
}

export const CLIENT_STATUS = {
  ACTIVE : "ACTIVE",
  SUSPENDED : "SUSPENDED",
}

export const CLIENT_STATUS_NAME = {
  ACTIVE : "Active",
  SUSPENDED : "Suspended",
}

export const CLIENT_STATUS_CHOICES = [
  {
    id : CLIENT_STATUS.ACTIVE,
    name : CLIENT_STATUS_NAME.ACTIVE,
  },
  {
    id : CLIENT_STATUS.SUSPENDED,
    name : CLIENT_STATUS_NAME.SUSPENDED,
  },
];

export const STATUS_MESSAGE_TYPES = {
  INFO : 'info',
  WARNING : 'warning' ,
  DANGER : 'danger' ,
  SUCCESS : 'success',
};

export const STATUS_MESSAGE_TYPES_NAMES = {
  INFO : 'Info',
  WARNING : 'Warning' ,
  DANGER : 'Danger' ,
  SUCCESS : 'Success',
};

export const STATUS_MESSAGE_COMPONENTS = {
  CORPORATE_PORTAL : 'Corporate Portal',
  USER_JOURNEY : 'User Journey' ,
};

export const STATUS_MESSAGE_TYPES_OPTIONS =[
  { id: STATUS_MESSAGE_TYPES.INFO, name: STATUS_MESSAGE_TYPES_NAMES.INFO },
  { id: STATUS_MESSAGE_TYPES.WARNING, name: STATUS_MESSAGE_TYPES_NAMES.WARNING },
  { id: STATUS_MESSAGE_TYPES.DANGER, name: STATUS_MESSAGE_TYPES_NAMES.DANGER },
  { id: STATUS_MESSAGE_TYPES.SUCCESS, name: STATUS_MESSAGE_TYPES_NAMES.SUCCESS },
];

export const TRANSACTION_CODES = [
  { id: ')))', name: ')))'},
  { id: 'ATM', name: 'ATM'},
  { id: 'BP', name: 'BP'},
  { id: 'CR', name: 'CR'},
  { id: 'DD', name: 'DD'},
  { id: 'DR', name: 'DR'},
  { id: 'INT', name: 'INT'},
  { id: 'OBP', name: 'OBP'},
  { id: 'VIS', name: 'VIS'},
]

export const BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS = {
  IN_PROGRESS : "IN_PROGRESS",
  TIMEOUT : "TIMEOUT",
  COMPLETED : "COMPLETED",
  COMPLETED_WITH_WARNINGS : "COMPLETED_WITH_WARNINGS",
  FAILED : "FAILED",
}

export const BANK_STATEMENT_JOB_IMPORT_WORKFLOW_STATUS_TITLE = {
  IN_PROGRESS : "In Progress",
  TIMEOUT : "A File is taking more time than expected",
  COMPLETED : "Completed",
  COMPLETED_WITH_WARNINGS : "Completed with warnings",
  FAILED : "Failed",
}

export const FILE_TYPE = {
  CSV : "CSV",
  PDF : "PDF",
}

export const SAME_ACCOUNT_EXIST_ERROR_CODE = 400;

export const DISABLE_MATTER_TOOLTIP_MESSAGE = "Scan bank statements or upload CSVs into Armalytix and run reports on the data. Email sales@armalytix.com to get access.";

export const UNAUTHORIZED_ACCESS = {
  title : "Access Denied!",
  message1 : "You don't have the correct access permissions",
  message2 : "please contact support@armalytix.com"
}

export const FORBIDDEN_ERROR_CODE = 403;
