import * as React from "react";
import { useState, useEffect } from "react";
import {
  Filter,
  TextInput,
  SelectInput,
  useDataProvider,
  Loading,
  AutocompleteInput,
} from 'react-admin'
import { ActionTitles, SCHEDULE_STATUS_FILTER, SCHEDULE_FREQUENCY_FILTER } from "../Constants";
import {width} from '../Styles'
import { Error404 } from "../errors/Error404";

export const SchedulesFilter = (props) => {
  return (
    <Filter variant="outlined" {...props}>
      <TextInput
        label="Search"
        source="scheduleSearchValue"
        size="small"
        variant="outlined"
        alwaysOn
        resettable
        style={{ width: "200px" }}
      />
      <SelectInput
        label="Frequency"
        source="scheduleFrequency"
        variant="outlined"
        choices={SCHEDULE_FREQUENCY_FILTER}
        alwaysOn
      />
      <SelectInput
        label="Status"
        source="scheduleStatus"
        variant="outlined"
        choices={SCHEDULE_STATUS_FILTER}                
        alwaysOn
      />
    </Filter>
  );
};

export const SchedulesAdminFilter = (props) => {
  var partnerChoices = [];
  const dataProvider = useDataProvider();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider
      .getClientNameList("admin/client/names")
      .then(({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error404 />;
  partnerChoices = data;

  return (
    <Filter {...props}>
      <AutocompleteInput
        source="clientId"
        name="clientId" //check this while integration
        variant="outlined"
        size="small"
        label={ActionTitles.CORPORATE_PARTNER}
        choices={partnerChoices}
        sx={width.fixed.w300}
        resettable
        alwaysOn
      />
      <TextInput
        label="Search"
        source="scheduleSearchValue"
        size="small"
        variant="outlined"
        alwaysOn
        resettable
        sx={width.fixed.w200}
      />
      <SelectInput
        label="Frequency"
        source="scheduleFrequency"
        variant="outlined"
        choices={SCHEDULE_FREQUENCY_FILTER}
        alwaysOn
      />
      <SelectInput
        label="Status"
        source="scheduleStatus"
        variant="outlined"
        choices={SCHEDULE_STATUS_FILTER}        
        alwaysOn
      />
    </Filter>
  );
};
