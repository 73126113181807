import * as React from "react";
import {
  AutocompleteInput,  
  Filter,
  Loading,
  TextInput,
  useDataProvider,
} from "react-admin";
import { parseUserNameChoicesForClients } from "../../mappers/Matter";
import { width } from "../Styles";
import { ActionTitles } from "../Constants";
import { parseUserNameChoicesForAdmins } from "../../utils/util";
import { Error404 } from "../errors/Error404";

export const MattersFilter = (props) => {
  const dataProvider = useDataProvider();
  const [partnerChoices, setPartnerChoices] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();

  React.useEffect(() => {
    if (props.isAdmin) {
      dataProvider
        .getClientNameList("admin/client/names")
        .then(({ data }) => {
          setPartnerChoices(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    else
      setLoading(false);
  }, [dataProvider, props.isAdmin]);

  if (loading) return <Loading />;
  if (error) return <Error404 />;

  return (!loading &&
    <Filter {...props}>
      {props.isAdmin &&
        <AutocompleteInput
          source="clientId"
          name="clientId"
          variant="outlined"
          size="small"
          label={ActionTitles.CORPORATE_PARTNER}
          choices={partnerChoices}
          resettable
          sx={width.fixed.w200}
          alwaysOn
        />
      }
      <TextInput
        label="Search"
        source="searchValue"
        size="small"
        variant="outlined"
        alwaysOn
        resettable
      />
      <AutocompleteInput
        label="User Name"
        source="userId"
        choices={props.isAdmin ? parseUserNameChoicesForAdmins(props.userNames) : parseUserNameChoicesForClients(props.userNames)}
        variant="outlined"
        size="small"
        sx={width.fixed.w250}
        resettable
        alwaysOn
      />
    </Filter>
  )
};