import * as React from "react";
import { Grid } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import { BooleanField, Show, SimpleShowLayout, UrlField, useRecordContext } from 'react-admin'
import { trueCircleIcon, falseCircleIcon } from "../common/StyledIcons";
import ListActions from '../common/ListActions'
import { ActionTitles, ENABLED_FEATURES_ENUM } from '../Constants'
import { contentCenter, padding0, padding2, textColor, textDecoration } from '../Styles'
import { isAdminUser, isFeatureEnabled } from "../DataProvider";

export const SettingsShow = () => (
  <Show
    actions={
      <ListActions
        title={ActionTitles.USER_DETAILS}
        showEditButton={true}
      />
    }
    sx={contentCenter}
  >
    <SimpleShowLayout>
      <SettingsShowGrid />
    </SimpleShowLayout>
  </Show>
);

const SettingsShowGrid = () => {
  const record = useRecordContext();

  return (
    <Grid container flexGrow={1}>
      <Grid xs={12}>
        <List sx={padding0.y}>
          <ListComponentForSettings title="First Name" value={record["firstName"]} />
          <Divider light />
          <ListComponentForSettings title="Last Name" value={record["lastName"]} />
          <Divider light />
          <ListComponentForSettings title="Email" value={record["email"]} />
          <Divider light />
          <ListComponentForSettings
            title="Email Report Notification"
            value={
              <>
                <BooleanField
                  source="emailReportNotifications"
                  TrueIcon={trueCircleIcon}
                  FalseIcon={falseCircleIcon}
                />
              </>
            }
          />
          {(isAdminUser() || isFeatureEnabled(ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT)) &&
            <>
              <Divider light />
              <ListComponentForSettings
                title="Email Statement Conversion Notification"
                value={
                  <>
                    <BooleanField
                      source="emailStatementConversionNotifications"
                      TrueIcon={trueCircleIcon}
                      FalseIcon={falseCircleIcon}
                    />
                  </>
                }
              />
            </>
          }
          <Divider light />
          <ListComponentForSettings title="Secondary Email" value={record["secondaryEmail"]} />
          <Divider light />
          <ListItem sx={padding2.y}>
            <Grid container>
              <Grid xs={12} md={4} sx={textColor.gray}>2FA</Grid>
              <Grid xs={12} md={8}>
                <UrlField
                  source="fa"
                  label="2FA"
                  href={
                    process.env.REACT_APP_KEYCLOAK_LOGIN_URL +
                    "/realms/" +
                    process.env.REACT_APP_KEYCLOAK_REALM +
                    "/protocol/openid-connect/auth?response_type=code&client_id=" +
                    process.env.REACT_APP_KEYCLOAK_CLIENT_ID +
                    "&redirect_uri=" +
                    encodeURIComponent(window.location.href) +
                    "&kc_action=CONFIGURE_TOTP"
                  }
                  sortable={false}
                  sx={textDecoration.none}
                >
                  Set up
                </UrlField>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
};

const ListComponentForSettings = (props) => {
  return (
    <ListItem sx={padding2.y}>
      <Grid container>
        <Grid xs={12} md={4} sx={textColor.gray}>
          {props.title}
        </Grid>
        <Grid xs={12} md={8}>{props.value}</Grid>
      </Grid>
    </ListItem>
  );
};
