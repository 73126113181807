import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  EditButton,
  UrlField,
  ShowButton,
  BooleanField,
} from "react-admin";
import defaultsDeep from 'lodash/defaultsDeep';
import cloneDeep from 'lodash/cloneDeep';
import ListActions from "../common/ListActions";
import { ActionTitles, ENABLED_FEATURES_ENUM, RESOURCES_BASEPATH } from "../Constants";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { trueCircleIcon, falseCircleIcon } from "../common/StyledIcons";
import { cellContentCentered, dataGrid, iconAction, linkButton, listRoot } from '../Styles'
import { isAdminUser, isFeatureEnabled } from "../DataProvider"

export const Settings = () => {
  return (
    <List
      sx={listRoot}
      actions={<ListActions title={ActionTitles.SETTINGS} />}
      exporter={false}
      pagination={false}
    >
      <Datagrid
        sx={defaultsDeep(cloneDeep(dataGrid.base), cloneDeep(dataGrid.last2ColsActions))}
        bulkActionButtons={false}
      >
        <TextField source="firstName" label="FIRST NAME" sortable={false} />
        <TextField source="lastName" label="LAST NAME" sortable={false} />
        <TextField source="email" label="EMAIL" sortable={false}></TextField>
        <BooleanField
          source="emailReportNotifications"
          label="EMAIL REPORT NOTIFICATION"
          sx={cellContentCentered}
          TrueIcon={trueCircleIcon}
          FalseIcon={falseCircleIcon}
          sortable={false}
        />
        {(isAdminUser() || isFeatureEnabled(ENABLED_FEATURES_ENUM.UPLOAD_BANK_STATEMENT)) &&
          <BooleanField
            source="emailStatementConversionNotifications"
            label="EMAIL STATEMENT CONVERSION NOTIFICATION"
            sx={cellContentCentered}
            TrueIcon={trueCircleIcon}
            FalseIcon={falseCircleIcon}
            sortable={false}
          />
        }
        <TextField source="secondaryEmail" label="SECONDARY EMAIL" />
        <UrlField
          source="fa"
          label="2FA"
          href={
            process.env.REACT_APP_KEYCLOAK_LOGIN_URL +
            "/realms/" +
            process.env.REACT_APP_KEYCLOAK_REALM +
            "/protocol/openid-connect/auth?response_type=code&client_id=" +
            process.env.REACT_APP_KEYCLOAK_CLIENT_ID +
            "&redirect_uri=" +
            encodeURIComponent(window.location.href) +
            "&kc_action=CONFIGURE_TOTP&armasource=portal"
          }
          sortable={false}
          sx={linkButton}
        >
          2FA
        </UrlField>
        <FunctionField
          source="View"
          sx={cellContentCentered}
          label={false}
          sortable={false}
          render={(record) => (
            <ShowButton
              icon={<RemoveRedEyeOutlinedIcon />}
              size="medium"
              sx={iconAction}
              label={false}
              basePath={RESOURCES_BASEPATH.SETTINGS}
              record={record}
            ></ShowButton>
          )}
        />
        <FunctionField
          source="Edit"
          sx={cellContentCentered}
          label={false}
          sortable={false}
          render={(record) => (
            <EditButton
              icon={<EditOutlinedIcon />}
              size="medium"
              sx={iconAction}
              label={false}
              basePath={RESOURCES_BASEPATH.SETTINGS}
              record={record}
            ></EditButton>
          )}
        />
      </Datagrid>
    </List>
  );
}


