import { ROLES_ASSIGNMENT }  from "../components/Constants"

export function parseRolesAssignmentForCreate(roles) {
  const data = {};
  data[ROLES_ASSIGNMENT.MAP] = roles;
  data[ROLES_ASSIGNMENT.UNMAP] = [];

  return JSON.stringify(data);
}

export function parseRolesForAssignment(preSelectedValues, postSelectedValues) {  
  const rolesToUnMap = preSelectedValues.filter(roleId => !postSelectedValues.includes(roleId));
  const rolesToMap = postSelectedValues.filter(roleId => !preSelectedValues.includes(roleId));
  
  const data = {};
  data[ROLES_ASSIGNMENT.MAP] = rolesToMap;
  data[ROLES_ASSIGNMENT.UNMAP] = rolesToUnMap;
  
  return JSON.stringify(data);
}