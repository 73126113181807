import React, { useEffect, useState } from "react";
import { AutocompleteArrayInput, Button, Loading, SimpleForm, useDataProvider, useNotify, useRefresh } from "react-admin";
import { useFormContext } from "react-hook-form";
import { accordion, accordionActionButton, autoCompleteField, iconAction, padding0, padding2, textColor } from "../Styles";
import { CircularProgress, Grid, List, ListItem, ListItemText } from "@mui/material";
import CustomAccordian from "../../utils/CustomAccordian";
import { EVENT_REASON, ROLE_NAMES, ROLE_OPTIONS, ROLE_OPTIONS_FOR_CLIENT_ADMIN } from "../Constants";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomDialog from "../../utils/CustomDialog";
import isEqual from "lodash/isEqual";
import { isClientAdminUser } from "../DataProvider";

export const UserRolesAccordian = (props) => {
    const [loading, setLoading] = useState(true);
    const [deleting, setDeleting] = useState();
    const [mappedRoles, setMappedRoles] = useState([]);
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();

    useEffect(() => {
        setMappedRoles(props.roles);
        setLoading(false);
        // eslint-disable-next-line
    }, [props.userId, props.roles]);

    const deleteRoleMapping = (role) => {
        setDeleting(role);
        dataProvider
            .unMapRole({ userId: props.userId, roleId: role })
            .then(({ json }) => {
                notify('Role was removed successfully for this user', { type: 'info', autoHideDuration: 1000 });
                setDeleting(undefined);
                refresh();
            })
            .catch((error) => {
                notify('Something went wrong while removing the role', { type: 'warning', autoHideDuration: 1000 });
                setDeleting(undefined);
            });
    }

    return (
        <ListItem sx={padding2.y} key="rolesContainer">
            <Grid container>
                <Grid item xs={12} md={4} sx={{ ...textColor.gray }}>Roles</Grid>
                <Grid item xs={12} md={props.isShow ? 6 : 5}>
                    <CustomAccordian
                        title={"User Roles"}
                        isShow={props.isShow}
                        defaultExpanded={true}
                    >
                        {loading && <Loading />}
                        {!loading && (
                            <List sx={padding0.y}>
                                {mappedRoles && mappedRoles.map((role) => {
                                    return (
                                        <ListItem sx={accordion.listItem} key={role}>
                                            <ListItemText primary={ROLE_NAMES[role]} disableTypography />
                                            {!props.isShow &&
                                                <Button
                                                    sx={iconAction}
                                                    size="medium"
                                                    onClick={() => deleteRoleMapping(role)}
                                                >
                                                    {deleting === role ? <CircularProgress size={18} /> : <DeleteOutlinedIcon />}
                                                </Button>
                                            }
                                        </ListItem>
                                    )
                                })}
                            </List>
                        )}
                    </CustomAccordian>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                    {!props.isShow &&
                        <UserRolesDialog
                            preSelectedChoices={mappedRoles}
                            userId={props.userId}
                            isEdit={props.isEdit}
                            deleting={deleting}
                        />
                    }
                </Grid>
            </Grid>
        </ListItem>
    );
}

export const UserRolesDialog = (props) => {
    const [adding, setAdding] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [selectedRoles, setSelectedRoles] = React.useState([]);
    const notify = useNotify();
    const refresh = useRefresh();
    const form = useFormContext();
    const dataProvider = useDataProvider();
    const isClientAdmin = isClientAdminUser();

    const handleClick = () => {
        setOpen(true);
    }

    const handleDialogClose = (event, reason) => {
        if (reason !== EVENT_REASON.BACKDROP_CLICK) {
            setOpen(false)
        }
    };

    const handleSubmitForEdit = () => {
        if (!isEqual(props.preSelectedChoices, selectedRoles)) {
            setAdding(true);
            dataProvider
                .updateAssignmentOfRoles({
                    userId: props.userId,
                    preSelectedValues: props.preSelectedChoices,
                    postSelectedValues: selectedRoles
                })
                .then(({ data }) => {
                    notify('Roles Mapped', { type: 'success', autoHideDuration: 1000 });
                    setAdding(false);
                    refresh();
                })
                .catch((error) => {
                    notify('Something went wrong while updating the roles for user', { type: 'warning', autoHideDuration: 1000 });
                    setAdding(false);
                });
        }
        setOpen(false);
    }

    const handleSubmitForCreate = () => {
        form.setValue('roles', selectedRoles);
        setOpen(false);
    }

    return (
        <>
            <Grid container >
                <Grid item display={props.isEdit ? "none" : "block"} xs={12} md={8}>
                    <List sx={padding0.y}>
                            {form.getValues()?.roles && form.getValues()?.roles.map((role) => {
                                return (
                                    <ListItem sx={{ ...accordion.listItem, "&:first-of-type": { borderTop: 0 } }} key={role}>
                                        <ListItemText primary={ROLE_NAMES[role]} disableTypography />
                                    </ListItem>
                                )
                            })}
                        </List>
                </Grid>
                <Grid item xs={12} md={props.isEdit ? 12 : 4}>
                    <Button
                        sx={{ ...accordionActionButton, marginTop: props.isEdit ? "15px !important" : "8px !important" }}
                        label={"Add Roles"}
                        size="small"
                        onClick={handleClick}
                        disabled={adding || props.deleting !== undefined}
                    >
                        {adding ? <CircularProgress size={18} color="white" /> : <AddCircleOutlineIcon />}
                    </Button>
                </Grid>
            </Grid>
            <CustomDialog
                openDialog={open}
                closeDialog={handleDialogClose}
                title="Map Roles To User"
                content={
                    <SimpleForm
                        toolbar={null}
                        sx={{ ...autoCompleteField, overflowY : "auto" }}
                    >
                        <AutocompleteArrayInput
                            label="Roles"
                            source="roles"
                            choices={isClientAdmin ? ROLE_OPTIONS_FOR_CLIENT_ADMIN : ROLE_OPTIONS}
                            variant="outlined"
                            fullWidth={true}
                            defaultValue={props.isEdit ? props.preSelectedChoices : selectedRoles}
                            onChange={(e) => { setSelectedRoles(e) }}
                        />
                    </SimpleForm>
                }
                handleSubmit={(props.isEdit) ? handleSubmitForEdit : handleSubmitForCreate}
            />
        </>
    );
};