import { margin0, pageBox, pageCentered, warningMessage } from "../Styles";

export const AccessDenied = (props) => {
  const { title, message1, message2 } = props; 
  return (
    <div style={pageCentered}>
      <div style={pageBox}>
        <h1 style={warningMessage}>{title}</h1>
        <h2 style={margin0.default}>
          <div>{message1}</div>
          <div>{message2}</div>
        </h2>
      </div>
    </div>
  );
};
