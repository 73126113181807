import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {accordion,margin0,padding0,width} from '../components/Styles'

const CustomAccordian = (props) => {
  const {
    title,
    isShow,
    children,
    defaultExpanded,
  } = props;

  return(
    <Accordion sx={{...accordion.container, ...(isShow ? margin0.y : {}) }} defaultExpanded={defaultExpanded} >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        { title }
      </AccordionSummary>
      <AccordionDetails sx={padding0.top}>
        <Typography sx={width.fluid.w100}>
          { children }
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

export default CustomAccordian;