import React, { useEffect, useState } from "react";
import {
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh
} from "react-admin";
import { useLocation, useParams } from "react-router-dom";
import { ActionTitles, EVENT_REASON, RESOURCES, SAME_ACCOUNT_EXIST_ERROR_CODE, SUB_RESOURCES } from "../Constants";
import { Divider, Grid, IconButton, List, ListItem } from "@mui/material";
import { contentCenter, dialog, iconAction, padding0, padding2, textColor } from "../Styles";
import ListActions from "../common/ListActions";
import { Transactions } from "./Transactions";
import { extractQueryParamsFromURL } from "../../mappers/Report";
import { isAdminUser } from "../DataProvider";
import CustomTooltip from "../../utils/CustomTooltip";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SimpleFormContainerComponent from "../common/SimpleFormContainerComponent";
import { useFormContext, useWatch } from "react-hook-form";
import CustomDialog from "../../utils/CustomDialog";
import isUndefined from "lodash/isUndefined";
import { isNull } from "lodash";

export const ViewAccount = () => {
  const isAdmin = isAdminUser();
  const { matterId, accountId } = useParams();
  const dataProvider = useDataProvider();
  const [bankDataMap, setBankDataMap] = useState(new Map());
  const notify = useNotify();
  const location = useLocation();
  const clientId = extractQueryParamsFromURL(location?.search, 'clientId');

  useEffect(() => {
    fetchBanks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBanks = () => {
    dataProvider.getAllBanks()
      .then((bankData) => {
        setBankDataMap(bankData.data);
      })
      .catch((error) => {
        notify('Something went wrong while fetching Banks.', "warning", { autoHideDuration: 1000 });
      });
  }

  return (
    <>
      <Show
        actions={
          <ListActions
            title={ActionTitles.MATTER_ACCOUNT_DETAILS}
            showViewButton={true}
            showDeleteButton={false}
            resource={RESOURCES.MATTERS}
            record={{ id: (isAdmin ? 'client/' + clientId + '/matter/' + matterId : matterId) }}
          />
        }
        renderButtonAndSearch={false}
        sx={contentCenter}
        resource={SUB_RESOURCES.ACCOUNTS}
        id={accountId}
        queryOptions={{ meta: { matterId: matterId, clientId: clientId } }}
      >
        <SimpleShowLayout>
          <AccountsShowGrid banks={bankDataMap} matterId={matterId}/>
        </SimpleShowLayout>
      </Show>
      <Transactions matterId={matterId} accountId={accountId} clientId={clientId} bankDataMap={bankDataMap} />
    </>
  );
};

const AccountsShowGrid = (props) => {
  const record = useRecordContext();

  return (
    <Grid container flexGrow={1}>
      <Grid item xs={12}>
        <List sx={padding0.y}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <AccountUpdateListItem title="Account Number" value={record['accountNumber']} source="accountNumber" matterId={props.matterId}/>
              <ListItemComponentForShow title="Bank Name" value={props.banks.get(record.bankId)} />
              <AccountUpdateListItem title="Sort Code" value={record['sortCode']} source="sortCode" matterId={props.matterId}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <ListItemComponentForShow title="Date From" value={record["dateFrom"]} />
              <ListItemComponentForShow title="Date To" value={record["dateTo"]} />
              <ListItemComponentForShow title="Last Modified" value={record["lastModified"]} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <ListItemComponentForShow title="Txn Count" value={record["txnCount"]} noDivider={true} />
            </Grid>
          </Grid>
        </List>
      </Grid>
    </Grid>
  );
};

const ListItemComponentForShow = (props) => {
  return (
    <>
      <ListItem sx={padding2.y}>
        <Grid container>
          <Grid item xs={12} md={5} sx={textColor.gray}>{props.title}</Grid>
          <Grid item xs={12} md={7}>{props.value}</Grid>
        </Grid>
      </ListItem>
      {!props.noDivider && <Divider light />}
    </>
  );
};

const AccountUpdateListItem = (props) => {

  const { title, value } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);    
  }

  return(
    <>
    <ListItem sx={padding2.y}>
      <Grid container>
        <Grid item xs={12} md={5} sx={textColor.gray}>{title}</Grid>
        <Grid item xs={12} md={7} style={{ display : 'flex' }}>
          <span>{value}</span>
          <CustomTooltip title={ActionTitles.UPDATE} placement="top" >
            <IconButton
              size="small"
              sx={{...iconAction, padding : "0px 5px", borderRadius : "5px" }}
              onClick={handleClick}
            >
              <EditOutlinedIcon />
            </IconButton>
          </CustomTooltip>
          <SimpleForm
            toolbar={null}
            component={SimpleFormContainerComponent}
          >
            <AccountUpdateDialog setOpen={setOpen} open={open} {...props}/>
          </SimpleForm>
        </Grid>
      </Grid>
    </ListItem>
    <Divider light />    
    </>
  );
}

const AccountUpdateDialog = (props) => {  
  const dataProvider = useDataProvider();
  const values = useWatch();
  const form = useFormContext();
  const refresh = useRefresh();  
  const notify = useNotify();
  const { open, setOpen, value, matterId, source, title } = props;
  
  const onSuccess = () => {
    notify("Account Updated", { type: 'info', autoHideDuration: 2000 });    
    handleDialogClose();
    refresh();
  };

  const onFailure = (error) => {
    if(error.status === SAME_ACCOUNT_EXIST_ERROR_CODE){
      notify("An account with the same Account Number, Sort code and Bank already exists", { type: 'info', autoHideDuration: 2000 });
    }else{
      notify("Something went wrong while updating account", { type: 'info', autoHideDuration: 2000 });
    }    
    handleDialogClose();
    refresh();
  };

  const handleSubmit = () => {         
    dataProvider
        .update(RESOURCES.ACCOUNTS, { data : { ...values }, id: matterId})
        .then(onSuccess)
        .catch(onFailure)
  }

  const handleDialogClose = (event, reason) => {
    if(reason !== EVENT_REASON.BACKDROP_CLICK){
      form.setValue(source, value);
      setOpen(false);      
    }      
  };

  return(
  <CustomDialog 
    openDialog={open}
    closeDialog={handleDialogClose}
    handleSubmit={handleSubmit}
    title={ActionTitles.UPDATE + " " + title}
    cancelBtnTitle={ActionTitles.CANCEL}
    submitBtnTitle={ActionTitles.UPDATE}
    submitBtnIcon={<EditOutlinedIcon />}
    dialogPaperProps={dialog}
    content={
      <TextInput
        source={source}
        label={title}
        variant="outlined"
        size="small"
        defaultValue={value}         
      />
    }
    invalid={isNull(values[source]) || isUndefined(values[source])}
  />);
} 
